<template>
  <v-dialog
      v-model="g_show"
      hide-overlay
      persistent
      width="300"
  >
    <v-card color="primary" dark>
      <v-card-text>
        Пожалуйста подождите
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['g_show'],
  data: function () {
    return {
      l_show: false,
    }
  },
}
</script>

<style scoped>

</style>